.info-container {
    border-radius: 8px;
    border: 1px solid #F0F0F0;
    background: #FFF;
    width: 24vw;
    height: 716px;
    margin-right: 16px;
    padding: 24px 24px 24px 24px;
}

.info-title {
    color: #3C3C3C;
    font-family: 'Red Hat Text';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.info-body {
    color: #3C3C3C;
    font-family: 'Red Hat Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.progress-container {
    width: 100%;
    height: 73px;
    position: relative;
    margin-bottom: 24px;
}

.markers {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;
    color: #606060;
    font-family: 'Red Hat Text';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.progress-bar {
    position: absolute;
    top: 28px;
    width: 100%;
    height: 16px;
    border-radius: 16px;
    background: linear-gradient(90deg, #D78F05 0%, #BFC229 50.52%, #188B5B 100%);
}


.labels {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    color: #606060;
    font-family: 'Red Hat Text';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.table-container-small {
    display: none;
}

@media (max-width: 767px) {
    .info-container {
        border: none;
        background-color: transparent;
        width: 100%;
        height: 500px;
    }

    .info-title {
        display: none;
    }

    .progress-container {
        margin-top: 12px;
    }
}