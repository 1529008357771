.banner-container {
    margin-top: 32px;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 130px;
    padding: 0px 64px;
    align-items: center;
    gap: 32px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #E4EAFF;
}

.banner-text {
    color: #1A2732;
    font-family: 'Red Hat Text';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}

.banner-strong-text {
    color: #1C38A9;
}

.banner-button {
    display: flex;
    width: 297px;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    border: 2px solid #1A2732;
    background: #FFF;
    color: #1A2732;
    text-align: center;
    font-family: 'Red Hat Text';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
}

.spacer {
    flex: 1 0 0;
}

@media (max-width: 767px) {
    .banner-container {
        height: 500px;
        padding: 0px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        flex-shrink: 0;
    }

    .banner-container img {
        height: 200px;
    }

    .banner-text {
        color: #3C3C3C;
        text-align: center;
        font-family: 'Red Hat Text';
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
    }

    .spacer {
        display: none;
    }
}