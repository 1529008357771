* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  background-color: #F6F7FE;
  padding: 4.23%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  overflow-y: auto;
}

.content {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .content{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0;
  }
}