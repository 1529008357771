table {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-spacing: 0 1px;
}

th,
td {
    padding: 12px 16px;
}

table td,
table th {
    max-width: 150px;
}

th {
    background-color: #f3f5f7;
    overflow: hidden;
    color: #5c7389;
    text-overflow: ellipsis;
    font-family: "Red Hat Text";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

tbody td {
    border-bottom: 1px solid #e0e0e0;
    color: #1a2732;
    font-family: "Red Hat Text";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background-color: white;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 20px;
}

tbody th:nth-child(n+2),
tbody td:nth-child(n+2) {
    text-align: center;
    font-weight: 700;
}

table th:first-child {
    border-top-left-radius: 8px;
}

table th:last-child {
    border-top-right-radius: 8px;
}

table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}

table tr:last-child td {
    border-bottom: none;
}

.tableHeader {
    background: #f3f5f7;
}

.tableCell {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

td {
    word-wrap: break-word;
    overflow: hidden;
}

.table-container {
    height: 716px;
    flex: 1 0 0;
    background-color: white;
    box-shadow: 4px 8px 16px 0px rgba(34, 38, 42, 0.04),
        2px 4px 8px 0px rgba(34, 38, 42, 0.03);
    border-radius: 8px;
    overflow: hidden;
    padding: 0;
    position: relative;
}

.table-blur-button-container {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 486px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-blur-button {
    cursor: pointer;
    display: flex;
    width: 440px;
    height: 56px;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    border-color: #1C38A9;
    background: #1C38A9;
    color: #FFF;
    text-align: center;
    font-family: 'Red Hat Text';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border: none;
}

@media (max-width: 767px) {
    .table-container {
        display: none;
    }

    .table-container-small {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .table-card-container {
        display: flex;
        padding: 16px;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        border-bottom: 1px solid #F0F0F0;
        background: #FFF;
    }

    .table-card-line {
        color: #606060;
        font-family: Red Hat Text;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
}