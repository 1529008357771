.table-blur {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 716px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.table-blur::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 494px;
    /* border-radius: 16px 16px 0px 0px;
    background: rgba(255, 255, 255, 0.20); */
    backdrop-filter: blur(12px);
    z-index: 1;
}

@media (max-width: 767px) {
    .table-blur {
        height: 3132px;
    }

    .table-blur::after {
        height: 2600px;
    }

    .table-blur-button-container {
        height: 2600px;
    }
}